<template>
  <b-card class="border border-danger">
    <div class="d-flex justify-content-between flex-wrap my-1">
      <h4 class="text-danger">
        <feather-icon icon="AlertTriangleIcon" class="mb-25" size="18" /> {{ $t('settings.danger-zone') }}
      </h4>
    </div>
    <b-row>
      <b-col>
        <h5 class="mr-25 mt-50">
          {{ $t('settings.remove-community',{item: $t(`collectives.items.${type}`)}) }}
        </h5>
        <p>
          {{ $t('settings.remove-text') }}
        </p>
      </b-col>
      <b-col class="text-right">
        <b-button variant="danger" class="ml-1" @click="isModalOpened = true">
          <feather-icon icon="AlertTriangleIcon" size="16" />
          {{ $t('settings.remove-community',{item: $t(`collectives.items.${type}`)}) }}
        </b-button>
      </b-col>
    </b-row>
    <b-modal
      v-model="isModalOpened"
      :title="$t('unshare.title', {item: $t(`collectives.items.${type}`)})"
      size="lg"
      hide-footer
    >
      <feather-icon size="30" icon="AlertTriangleIcon" class="d-block mx-auto text-danger mb-1" />
      <h5 class="mb-2 px-2 text-center">
        {{ $t('settings.confirm', { itemName: translateTranslationTable(currentLocale, currentCollective.name) }) }}
      </h5>
      <div class="d-flex justify-content-center mb-2 flex-wrap">
        <b-button
          variant="primary"
          target="_blank"
          class="mt-25"
          @click="isModalOpened = false"
        >
          {{ $t('unshare.options.no') }}
        </b-button>
        <b-button
          variant="outline-danger"
          target="_blank"
          class="ml-md-1 mt-25"
          @click="removeCommunity"
        >
          {{ $t('unshare.options.yes') }}
        </b-button>
      </div>
    </b-modal>
  </b-card>
</template>

<script>
import ToastNotificationsMixin from '@core/mixins/toast-notifications/ToastNotificationsMixin';
import { translateTranslationTable } from '@/@core/libs/i18n/utils';

export default {
  name: 'MainDanger',
  mixins: [ToastNotificationsMixin],
  props: {
    type: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      isModalOpened: false,
    };
  },
  computed: {
    currentLocale() {
      return this.$store.state.locale.currentLocale;
    },
    currentCollective() {
      return this.$store.getters.currentCollective;
    },
    mainCollective() {
      return this.$store.getters.mainCollective;
    },
    parentCollective() {
      return this.$store.getters.parentCollective;
    },
  },
  created() {
    // ...
  },
  methods: {
    async removeCommunity() {
      await this.$store.dispatch('removeCommunity');
      if (this.currentCollective?.parentKey) {
        window.location.href = process.env.VUE_APP_COMMUNITY_TEMPLATE_URL.replace('{slug}', this.parentCollective.slug).replace(
          '{subdomain}',
          this.parentCollective.subdomain || 'app',
        );
      } else {
        window.location.href = process.env.VUE_APP_COMMUNITY_TEMPLATE_URL.replace('{slug}', '').replace(
          '{subdomain}',
          'app',
        );
      }
    },
    translateTranslationTable,
  },
};
</script>
