<template>
  <b-card class="border border-danger">
    <div class="d-flex justify-content-between flex-wrap my-1">
      <h4 class="text-danger">
        <feather-icon icon="AlertTriangleIcon" class="mb-25" size="18" /> {{ $t('settings.danger-zone') }}
      </h4>
    </div>
    <b-row>
      <b-col>
        <h5 class="mr-25 mt-50">
          {{ $t('unsubscribe-community.danger-zone.subtitle') }}
        </h5>
        <p class="font-weight-bold">
          {{ $t('unsubscribe-community.danger-zone.message') }}
        </p>
      </b-col>
      <b-col class="text-right">
        <b-button variant="danger" class="ml-1" @click="isModalOpened = true">
          <feather-icon icon="AlertTriangleIcon" size="16" />
          {{  $t('unsubscribe-community.cancel-subscription') }}
        </b-button>
      </b-col>
    </b-row>
    <b-modal
      v-model="isModalOpened"
      :title="$t('unsubscribe-community.cancel-subscription')"
      size="lg"
      hide-footer
    >
      <feather-icon size="30" icon="AlertTriangleIcon" class="d-block mx-auto text-danger mb-1" />
      <h5 class="text-center mb-2">
        {{ $t('unsubscribe-community.message', { communityName: currentCollective.name }) }}
      </h5>
      <div class="d-flex justify-content-center mb-2 flex-wrap">
        <b-button v-if="loggedMember" variant="outline-danger" @click="unsubscribeCommunity()">
          {{ $t('unsubscribe-community.cancel-subscription') }}
        </b-button>
        <b-button variant="primary" class="ml-md-2 mb-1 mb-md-0" @click="isModalOpened = false">
          {{ $t('unsubscribe-community.stay-subscribed') }}
        </b-button>
      </div>
    </b-modal>
  </b-card>
</template>
<script>
import ToastNotificationsMixin from '@core/mixins/toast-notifications/ToastNotificationsMixin';
import Service from '@/config/service-identifiers';

export default {
  name: 'DangerZone',
  mixins: [ToastNotificationsMixin],
  props: {
    userkey: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      isModalOpened: false,
    };
  },
  computed: {
    currentCollective() {
      return this.$store.getters.currentCollective;
    },
    loggedMember() {
      return this.$store.getters.loggedMember;
    },
  },
  watch: {
    userkey(newvalue) {
      return newvalue;
    },
  },
  methods: {
    async removeCommunity() {
      await this.$store.$service[Service.BackendClient].post('user/delete', {
        userKey: this.userkey,
      });
      this.$store.dispatch('logout');
      window.location.href = process.env.VUE_APP_LANDING_URL;
    },
    async unsubscribeCommunity() {
      await this.$store.dispatch('unsubscribeCommunity', { communityKey: this.currentCollective.key });
      this.logOut();
    },
    logOut() {
      this.$store.dispatch('logout');
    },
  },
};
</script>
